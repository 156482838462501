<!--
 * @FilePath: /dwh_front/src/views/objects/globalFunc.vue
 * @Author: lisushuang
 * @Date: 2021-10-17 16:29:31
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-08-04 15:29:23
 * @Description: 全局函数配置页面
-->
<template>
  <div class="h-full p-5">
    全局函数配置

    <div style="overflow-y: scroll; height: 90%" class="p-5">
      <div v-for="(item, index) in allFuncs" :key="index" style="margin-top: 20px">
        <p style="font-size: 18px; font-weight: bold">
          {{ item.name }} - {{ item.desc }}
        </p>
        <div
          style="margin-left: 10px"
          v-for="(configItem, index2) in item.inputs"
          :key="index2"
        >
          <div style="margin: 3px; font-size: 16px">
            {{ configItem.desc }} - {{ index2 }}
          </div>
          <div><el-input /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objects from "@/api/objects";
export default {
  name: "globalFunc",

  data() {
    return {
      allFuncs: [],
    };
  },
  methods: {},
  mounted() {
    objects.globalFunction.getAll().then((res) => {
      if (res.data.code == 200) {
        this.allFuncs = res.data.data;
      }
    });

    objects.globalFunction.getConfig("LoginFuncClass").then((res) => {
      if (res.data.code == 200) {
        this.nowShow == res.data.data;
      }
    });

    objects.globalFunction.getFunc("LoginFuncClass");
  },
};
</script>